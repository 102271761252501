<template>
  <div class="label-app">
    <div>标签名称：{{ labelData.labelName }}</div>
    <div>
      <span>数量：{{ selectList.length }}</span>
      <el-button
        size="small"
        type="primary"
        style="margin-left: 60px"
        @click="handleRemove"
        >移除</el-button
      >
    </div>
    <div class="select-list borderA">
      <div
        v-for="item in selectList"
        :key="item.appInfoId"
        class="select-item cursor"
      >
        <el-checkbox v-model="item.checked">
          <img class="icon" :src="item.filePath" />
        </el-checkbox>
        <div>{{ item.title }}</div>
      </div>
    </div>
    <div>
      <el-input
        size="small"
        placeholder="请输入搜索内容"
        v-model="serachKeyword"
        class="input-with-select"
      >
        <el-select
          size="small"
          slot="prepend"
          v-model="serachKeywordCode"
          placeholder="请选择类型"
        >
          <!-- <el-option label="APPID" :value="1"></el-option> -->
          <el-option label="应用名称" :value="2"></el-option>
          <el-option label="应用包名" :value="3"></el-option>
        </el-select>
      </el-input>
      <el-button size="small" type="primary" class="ml10" @click="handleSearch"
        >查询</el-button
      >
      <el-button
        size="small"
        type="primary"
        class="ml10"
        @click="handleAddSelectList"
        >添加</el-button
      >
    </div>
    <PageTable
      height="300"
      rowKey="appInfoId"
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @selection-change="handleSelectionChange"
      ref="multipleTableSelect"
    >
      <el-table-column
        type="selection"
        width="55"
        slot="prefix"
      ></el-table-column>
      <template slot-scope="{ row }" slot="filePath">
        <img class="icon" :src="row.filePath" />
      </template>
    </PageTable>
    <div class="page-tail">
      <el-button type="primary" @click="handleSubmit()">保存</el-button>
      <el-button @click="handleCancel()">取消</el-button>
    </div>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import { getApkList } from "./indexServe.js";
export default {
  components: { PageTable },
  mounted() {
    this.getTableList();
  },
  props: {
    labelData: {
      type: Object,
      default() {
        return {};
      },
    },
    appList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      serachKeywordCode: "",
      serachKeyword: "",
      tableData: {
        list: [],
      },
      columns: [
        { key: "appId", label: "APPID" },
        { key: "title", label: "应用名称" },
        { slot: "filePath", label: "应用图标", width: 120, align: "center" },
        { key: "packageName", label: "包名" },
      ],
      selectList: this.appList, // 已添加的应用列表
      selection: [], // 应用列表中，已勾选的应用列表
    };
  },
  computed: {
    selectIdList() {
      return this.selectList.map((i) => i.appInfoId);
    },
  },
  methods: {
    celearSelect() {
      this.$refs.multipleTableSelect.selectInit();
    },
    // 事件监听
    handleSubmit() {
      const data = {
        appIdList: this.selectIdList,
        labelId: this.labelData.labelId,
      };
      this.$emit("submit", data);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    handleRemove() {
      const list = this.selectList.filter((item) => !item.checked);
      this.selectList = [...list];
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleAddSelectList() {
      if (this.selection.length == 0) {
        this.$message.error("请勾选需要添加的应用");
        return;
      }
      this.addSelectList(this.selection);
    },

    // 后端请求
    // 获取列表
    async getTableList(params) {
      const data = {
        page: 1,
        size: 10,
        ...params,
      };
      if (this.serachKeyword) {
        data.chooseType = this.serachKeywordCode;
        data.keyword = this.serachKeyword;
      }
      const res = await getApkList(data);
      if (res) {
        this.tableData = res.data;
        return res.data;
      }
    },
    // 其他
    addSelectList(list) {
      const listTmp = [];
      list.forEach((item) => {
        if (!this.selectIdList.includes(item.appInfoId)) {
          listTmp.push(item);
        }
      });
      this.selectList.push(...listTmp);
    },
  },
  watch: {
    appList: {
      immediate: true,
      handler(list) {
        this.selectList = JSON.parse(JSON.stringify(list));
      },
    },
    // appList(list) {
    //   this.selectList = list;
    // },
  },
};
</script>
<style scoped lang="scss">
.label-app {
  font-size: 16px;
  & > div {
    margin: 10px;
  }
  .input-with-select {
    /deep/.el-input-group__prepend {
      background: #fff;
      width: 60px;
    }
  }
  .el-input {
    width: 400px;
  }
  .page-tail {
    text-align: end;
    margin-top: 20px;
  }
}
.icon {
  width: 60px;
  height: 60px;
}
.select-list {
  display: flex;
  flex-wrap: wrap;
  height: 150px;
  overflow: auto;
  .select-item {
    position: relative;
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    /deep/.el-checkbox__input {
      position: absolute;
      top: 0;
    }
  }
}
</style>
