import request from "@/config/serve.js";
import { ZHID } from "@/common/constant/constant.js";

// 查询
export function getLabelList(data) {
  return request("post", "/ozLabel/list", {
    isDeleted: false,
    languageId: ZHID,
    ...data,
  });
}
// 查询标签详情
export function getLabelDetailAll(params) {
  return request("get", "/ozLabel/item/detailAll", params);
}
//新增
export function addLabel(data) {
  return request("post", "/ozLabel/item/add", data);
}
//修改
export function updateLabel(data) {
  return request("post", "/ozLabel/item/edit", data);
}
//删除
export function deleteLabel(data) {
  return request("get", "/ozLabel/item/delete", data);
}
//批量删除
export function batchDeleteLabel(data) {
  return request("get", "/ozLabel/batch/delete", data);
}
// 查询语言列表
export function getLanguageList(params) {
  return request("get", "/language/all", params);
}
// 查询应用信息列表
export function getApkList(params) {
  return request("get", "/ozApkLabel/searchApp", params);
}
// 查询打上指定标签的应用集合
export function getSelectApkList(params) {
  return request("get", "/ozApkLabel/lapp", params);
}
//保存标签应用关系
export function saveLabelApp(data) {
  return request("post", "/ozApkLabel/LabelApp/save", data);
}
