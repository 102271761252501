<template>
  <div class="list-area">
    <el-row>
      <el-button
        size="small"
        type="primary"
        v-has="'add'"
        icon="el-icon-plus"
        @click="handleOperate('create')"
        >添加</el-button
      >
    </el-row>
    <el-row :gutter="6">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="searchData.labelName"
          placeholder="请输入标签名称"
        ></el-input
      ></el-col>
      <el-col :span="4"
        ><el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @selection-change="handleSelectionChange"
    >
      <el-table-column slot="prefix" type="selection" width="50" align="center">
      </el-table-column>
      <template slot-scope="{ row }" slot="languageNums">
        <el-popover trigger="hover" v-if="row.labelLangDesc">
          <div style="max-width: 250px">
            {{ row.labelLangDesc && row.labelLangDesc.join("、") }}
          </div>
          <a slot="reference" class="cursor">
            {{ row.labelLangDesc.length }}种
          </a>
        </el-popover>
      </template>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'lookinfo'"
            @click="handleOperate('view', row)"
          >
            <span class="colorGreen">查看</span>
          </el-button>
          <el-button
            type="text"
            v-has="'edit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          >
          <el-popconfirm
            v-has="'delete'"
            title="确定删除吗？"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" class="ml10" type="text">
              <span class="colorRed">删除</span>
            </el-button>
          </el-popconfirm>
          <el-button
            type="text"
            v-has="'app'"
            class="ml10"
            @click="handleOperate('app', row)"
            >应用管理</el-button
          >
        </template>
      </el-table-column>
    </PageTable>
    <div class="page-foot" v-has="'batchDelete'">
      <el-button size="small" type="primary" @click="handleBatchOperation"
        >批量删除</el-button
      >
    </div>
    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="visible"
    >
      <Detail
        :operationType="operationType"
        :detailData="detailData"
        :languageList="languageList"
        @submit="handleSubmit"
        @cancel="visible = false"
      />
    </el-drawer>
    <el-dialog
      top="10vh"
      width="60%"
      :close-on-click-modal="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="dialogVisible"
    >
      <AppManagement
        v-if="operationType === 'app'"
        :labelData="detailData"
        :appList="appList"
        @submit="handleSaveLabelApp"
        @cancel="dialogVisible = false"
        ref="AppManagementt"
      ></AppManagement>
    </el-dialog>
  </div>
</template>
<script>
import PageTable from "@/components/PageTable.vue";
import Detail from "./Detail.vue";
import AppManagement from "./AppManagement.vue";
import {
  getLabelList,
  deleteLabel,
  addLabel,
  updateLabel,
  getLanguageList,
  batchDeleteLabel,
  getLabelDetailAll,
  getSelectApkList,
  saveLabelApp,
} from "./indexServe";
export default {
  name: "labelManagement",
  components: { PageTable, Detail, AppManagement },
  mounted() {
    this.init();
  },
  data() {
    return {
      languageList: [],
      searchData: {},
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "labelId",
          label: "ID",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "labelName",
          label: "标签名称",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "languageNums",
          label: "语言",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "createTime",
          label: "创建时间",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "modifyTime",
          label: "更改时间",
          align: "center",
          "show-overflow-tooltip": true,
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      dialogVisible: false, // 控制dialog的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      selectionIdList: [], //所有已选的id列表
      appList: [], //编辑时绑定的应用列表
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      if (["create", "edit", "view"].includes(operationType)) {
        this.visible = true;
        if (operationType === "create") {
          this.detailData = {};
        } else {
          this.getLabelDetailAll(row);
        }
      } else if (["app"].includes(operationType)) {
        this.dialogVisible = true;
        this.detailData = { ...row };
        this.getSelectApkList(row);
      }
      this.operationType = operationType;
    },
    handleDelete(row) {
      deleteLabel({ labelId: row.labelId }).then((res) => {
        if (res && res.code === 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success(res.data);
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      const fcn = this.operationType === "edit" ? updateLabel : addLabel; // 编辑和新建分别调用不同接口
      fcn(formData).then((res) => {
        if (res && res.code === 0) {
          this.visible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success(res.data);
        }
      });
    },
    handleSaveLabelApp(formData) {
      saveLabelApp(formData).then((res) => {
        if (res && res.code === 0) {
          this.$message.success(res.data);
          this.dialogVisible = false;
          this.init();
        }
      });
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.labelId);
    },
    handleBatchOperation() {
      if (this.selectionIdList.length == 0) {
        this.$message.error("请勾选需要操作的数据");
        return;
      }
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          batchDeleteLabel({
            labelIdList: this.selectionIdList.join(","),
          }).then((res) => {
            if (res && res.code === 0) {
              this.getTableList({
                page: this.tableData.page,
                size: this.tableData.size,
              }); // 删除成功后刷新列表
              this.$message.success(res.data);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const res = await getLabelList({
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      });
      if (res) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 获取标签详情
    async getLabelDetailAll(row) {
      const res = await getLabelDetailAll({ labelId: row.labelId });
      if (res && res.code === 0) {
        this.detailData = { ...row, labelNameList: res.data };
      }
    },
    // 查询打上指定标签的应用集合
    async getSelectApkList(row) {
      const res = await getSelectApkList({ labelId: row.labelId });
      if (res && res.code === 0) {
        // this.appList = res.data;
        this.appList = [];
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].checked = false;
          this.appList.push(res.data[i]);
        }
        this.$refs.AppManagementt.celearSelect();
      }
    },
    // 获取语言列表
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    // 其他
    init() {
      this.getTableList();
      this.getLanguageList();
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
